/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import { ScrollContainer } from '../context/scrollBehavior'
import SEO from '../components/SEO'
import AnimateOnEntrance from '../components/fx/animate/AnimateOnEntrance'
import Loading from '../assets/media/Rhombus.gif'

// determine image sourcing by env
import useAuth from '../util/hooks/useAuth'
import { changePassword } from '../util/auth/register';
import ImageInView from '../components/fx/animate/ImageInView';

const PasswordResetPage = ({data}) => {

    useAuth();
    const [device, setDevice ] = useState('mobile')

    const [pass, setPasswordField] = useState('');
    const [confirmPass, setConfirmPasswordField] = useState('');
    const [temppass, setTempPass] = useState(false);
    const [user, setUser] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState(null);

    useEffect(() => {

        const validateHiddenFields = (str) => {
            return str !== "" && str !== " " && str.length > 2 && String(str) !== "undefined";
        }

        const submitValidationConfirmation = async () => {
            // get path name
            const path = window.location.pathname.split("/");
            const pathParts = path.slice(Math.max(path.length - 2, 0));

            const user = decodeURIComponent(pathParts[0]);
            const hash = pathParts[1];

            if (!validateHiddenFields(user) || !validateHiddenFields(hash)) {
                setError({
                    general: 'There was an error with the link you have been provided. Please try requesting a password reset again'
                })
            } else {
                // presumed valid
                setTempPass(hash);
                setUser(user);
            }
        }

        submitValidationConfirmation();
    }, []);

    const handleFormSubmission = (e) => {
        e.preventDefault();
        if (!submit) {
            setSubmit(true);
            setLoading(true);
        }
    }

    const validateFormFields = () => {

        if (!submit) return true;

        let errors = {}

        if (pass.length < 1) {
            errors.pass = 'Your password cannot be empty'
        }
        if (pass !== confirmPass) {
            // password too weak
            errors.confirmpass = 'Your passwords don\'t match.'
        }
        if (Object.keys(errors).length) {
            setError(errors);
            setSubmit(false);
            setLoading(false);
            return false;
        }
        return true;
    }

    // submit change of password
    useEffect(() => {

        async function fetchPasswordReset() {

            // invoke early
            setSubmit(false);

            // registration request
            const res = await changePassword({
                user,
                temppass,
                newpass: pass,
            });

            console.log('response', res);

            // there's an error, display it
            if (res.error) {
                setError(
                    {general: res.msg}
                );
                setSuccess(null);
            }
            // no error, do stuff
            else {
                setSuccess(true);
                setError({});
            }

            setLoading(false);
        }

        // validate form fields first
        if (validateFormFields()) {
            if (submit) {
                // if submit, fetch
                fetchPasswordReset();
            }
        }

    }, [submit]);

    return (
        <>
        <SEO title="Reset your password" />
        <Layout device={device} setDevice={setDevice} logo={data.logo} menu={data.allMenuMenuLink} footerMenu={data.footer_menu} locations={data.locations.edges}>
            <ScrollContainer force={true}>
                <section className="grid-section register-signup grid-content-container t-left">
                    <div className="t-content-container t-left">
                        <div className="grid-2 grid-justify-center grid-align-center grid-gap-lg">
                            <div className="grid-col">
                                <div className="t-heading-container">
                                    <AnimateOnEntrance className="fadeInLeft" device={device}>
                                        <h3 className="t-mxlarge t-heading">Reset your password</h3>
                                    </AnimateOnEntrance>
                                </div>
                                <div className="t-content-container t-left t-callout">
                                    <AnimateOnEntrance className="fadeInLeft" device={device}>
                                        <form
                                            onSubmit={handleFormSubmission}
                                        >
                                            <p>
                                                Please enter your new password and press "submit"
                                            </p>
                                            <br />
                                            {error.general && (
                                                <div className="callout error">
                                                    {error.general}
                                                </div>
                                            )}
                                            <div className="input-item">
                                                <label className="t-small" htmlFor="password">
                                                    New Password
                                                </label><br />
                                                <input
                                                    value={pass}
                                                    autoComplete="password"
                                                    id="password"
                                                    name="password"
                                                    onChange={(e) => setPasswordField(e.target.value)}
                                                    type="password"
                                                    placeholder="New Password"
                                                    data-error={error.pass && (true)}
                                                />
                                                {error.pass && (<span className="error"><br />{error.pass}</span>)}
                                            </div>
                                            <div className="input-item">
                                                <label className="t-small" htmlFor="confirmpassword">
                                                    Confirm New Password
                                                </label><br />
                                                <input
                                                    value={confirmPass}
                                                    autoComplete="confirmpassword"
                                                    id="confirmpassword"
                                                    name="confirmpassword"
                                                    onChange={(e) => setConfirmPasswordField(e.target.value)}
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                    data-error={error.confirmpass && (true)}
                                                />
                                                {error.confirmpass && (<span className="error"><br />{error.confirmpass}</span>)}
                                            </div>
                                            <br />
                                            {loading && (
                                                <div className="callout warning">
                                                    Submitting, please wait...
                                                    <img src={Loading} />
                                                </div>
                                            )}
                                            <input type="submit" data-loading={loading && (true)} value={loading ? 'Loading...' : 'SUBMIT'} />
                                            {success && (
                                                <div className="callout success">
                                                    Your password has been successfully changed. Please log in.
                                                </div>
                                            )}
                                        </form>
                                    </AnimateOnEntrance>
                                </div>
                            </div>
                            <div className="grid-col">
                                <AnimateOnEntrance className="fadeInRight" device={device}>
                                    <ImageInView src={"/media/su_grid.jpg"} />
                                </AnimateOnEntrance>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollContainer>
        </Layout>
        </>
    )
}

export default PasswordResetPage

export const PasswordResetQuery = graphql`
query PasswordResetQuery {
    allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "gatsby-menu"}}) {
        edges {
            node {
            enabled
            title
            expanded
            external
            langcode
            weight
            link {
                uri
            }
            drupal_parent_menu_item
            bundle
            drupal_id
            menu_name
            }
        }
    }
    locations: allNodeLocation {
        edges {
        node {
            field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
            }
            field_address_description
            field_address_short
            field_location_email
            field_location_fax_number
            field_location_phone_number
            title
            field_office_hours_description
        }
        }
    }
    footer_menu: allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "footer"}}) {
        edges {
            node {
                enabled
                title
                expanded
                external
                langcode
                weight
                link {
                    uri
                }
                bundle
                drupal_id
                menu_name
            }
        }
    }
}`