import React from "react";

const AnimateOnEntrance = ({children, className, device, style}) => {

    const localStyle = {
        opacity: 0
    };

    const newStyle = {...localStyle, ...style};

    return (
        <div className={`animated ${className}`} style={newStyle}>{children}</div>
    )
}

export default AnimateOnEntrance;